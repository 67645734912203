<template>
  <div style="position: relative; top: 40%; text-align: center">
    <h1>404</h1>
    <h1>Page not found</h1>
    <router-link :to="link" style="font-size: 26px">Go home</router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Error',
  data() {
    return {
      link: ''
    };
  },
  created() {
    if (this.getUserData.roleNames[0].toLowerCase() === 'admin' || this.getUserData.roleNames[0].toLowerCase() === 'dealer') {
      this.link = 'venues'
    } else {
      this.link = 'new-orders'
    }
  },
  computed: {
    ...mapGetters({
      getUserData: 'app/getUserData',
    }),
  }
};
</script>
